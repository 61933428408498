import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
//合并对象的方法
function concatkeyvalue(map){//map = [{..},{..}]
let newmap = {}
 map.forEach(items => {
 for (var key in items) {
 newmap[key] = items[key]
}
})
 return newmap
}
//将各个文件的中英文翻译全部合并
let zh = concatkeyvalue([require('./zh_CN')])
let en = concatkeyvalue([require('./en_US')])
silentTranslationWarn: true
const i18n = new VueI18n({
 locale: 'en', // 语言标识
 messages: {
 'zh': zh,
 'en': en
 }
})
export default i18n